export function MoniepointLogoLetter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3498 11.4417L22.4098 -0.0078125H16.7403C16.7403 -0.0078125 12.422 8.80624 12.0402 10.0068H11.9814L7.42816 -0.0078125H1.72926L0.78924 9.91898L-0.00390625 19.9922H3.46243C4.9606 19.9922 5.54811 19.348 5.66562 17.7667C5.66562 17.7667 6.25313 8.5427 6.165 7.1957H6.22376L6.40001 7.81064C6.63502 8.63055 9.86635 16.5076 9.86635 16.5076H14.1258C14.1258 16.5076 17.3572 8.74768 17.7684 7.1957H17.8272L18.3266 17.7667C18.4441 19.348 19.061 19.9922 20.5298 19.9922H23.9961L23.3498 11.4417Z"
        fill="currentColor"
      />
    </svg>
  );
}
