import { isServer } from '@tanstack/react-query';
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';

import { ROUTES } from '@/shared/routes';

import { restoreServerAccessToken } from './server-utils';
import { removeAccessToken, restoreAccessToken } from './utils';

import { config } from '@/config';

async function withAccessToken(config: InternalAxiosRequestConfig) {
  if (config.headers['X-Skip-Auth']) {
    // Remove the header if auth is skipped
    delete config.headers['X-Skip-Auth'];

    return config;
  }

  const token = isServer
    ? await restoreServerAccessToken()
    : restoreAccessToken();

  if (token) {
    // Set the Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

function with401Redirect(error: AxiosError) {
  if (isAxiosError(error) && error.response?.status === 401) {
    // if (!isServer) {
    //   // Remove the token if the error is a 401
    //   removeAccessToken();
    //   // Redirect to the login page with the current path as the redirect URL
    //   window.location.href = ROUTES.$SIGN_IN(window.location.pathname);
    // }
  }

  return Promise.reject(error);
}

function createApi(
  baseURL: string,
  { headers = {} }: { headers?: Record<string, string> } = {},
) {
  const api = axios.create({ baseURL, headers: { ...headers } });
  api.interceptors.request.use(withAccessToken);
  // api.interceptors.response.use(undefined, with401Redirect);

  return api;
}

export const mockApi = createApi('/api/v1/mocks');
export const baseApi = createApi(config.API_BASE_URL);
export const walletApi = createApi(config.WALLET_API_BASE_URL);
export const referralApi = createApi(`${config.API_BASE_URL}/referral/v1`);
export const identityApi = createApi(`${config.API_BASE_URL}/identity/v1`);
export const beneficiaryApi = createApi(config.BENEFICIARY_API_BASE_URL);
export const interbankTransferApi = createApi(
  config.INTERBANK_TRANSFER_API_BASE_URL,
  {
    headers: {
      Accept: 'application/json',
    },
  },
);
export const xcallyAPI = createApi(config.XCALLY_API_BASE_URL);
