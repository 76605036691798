'use client';

import { MutableRefObject, useEffect, useRef, useState } from 'react';

function useBlur<T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const ref = useRef<T | null>(null);
  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    const onBlur = () => setIsBlurred(true);
    const onFocus = () => setIsBlurred(false);

    const element = ref.current;

    if (element) {
      element.addEventListener('blur', onBlur);
      element.addEventListener('focus', onFocus);
    }

    return () => {
      if (element) {
        element.removeEventListener('blur', onBlur);
        element.removeEventListener('focus', onFocus);
      }
    };
  }, []);

  return [ref, isBlurred, setIsBlurred];
}

export default useBlur;
