'use client';

import { useHttpMutation } from '@/shared/hooks';

import {
  LoginUserErrorResponse,
  LoginUserPayload,
  LoginUserSuccessResponse,
} from '../types';

const useLoginMutation = useHttpMutation<
  LoginUserPayload,
  LoginUserSuccessResponse,
  LoginUserErrorResponse
>;

const useLogin = (options?: Parameters<typeof useLoginMutation>[0]) => {
  return useLoginMutation({
    method: 'POST',
    url: '/identity/v1/logins',
    ...options,
  });
};

export default useLogin;
