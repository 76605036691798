import { Text, TextProps } from '../text';

export function PageSubtitle(props: TextProps) {
  return (
    <Text
      align="center"
      variant="body-default"
      color="content-heading-alternative"
      {...props}
    />
  );
}
