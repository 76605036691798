import { textVariants, type TextVariants } from './text.cx';
import {
  createComponent,
  HTMLCustomVariantsAwareProps,
  useTailwindVariants,
} from '@/ui/system';

export interface TextProps
  extends HTMLCustomVariantsAwareProps<'p', TextVariants> {}

export const useTextVariants = (props: TextProps = {}) =>
  useTailwindVariants(props, textVariants);

export const Text = createComponent<TextProps>(
  ({ as: Component = 'p', ...props }, ref) => {
    const blueprint = useTextVariants(props);

    return <Component ref={ref} {...blueprint.ownProps} />;
  },
);

Text.displayName = 'Text';
