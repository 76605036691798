import { useMediaQuery } from './use-media-query';

export function useA11yDialog({ id, open }: { id: string; open?: boolean }) {
  return {
    isDesktop: useMediaQuery('sm'),
    controlProps: {
      'aria-expanded': open,
      'aria-controls': id,
    },
    contentProps: {
      id,
      'aria-describedby': id,
    },
  };
}
