import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import { toast } from 'sonner';

import { baseApi } from '@/lib/api';
import { logError } from '@/lib/utils';

import { ApiError, getApiErrorMessage } from '../types';

export type UseHttpMutationAxiosOptions<Data> = Pick<
  AxiosRequestConfig<Data>,
  // Pick as you need, make sure to also extract below or else these will be forwarded to useMutation instead of axios.request
  'url' | 'data' | 'method' | 'headers'
>;

export type UseHttpMutationFunctionProps<Data> = {
  data?: Data;
  config?: AxiosRequestConfig;
};

type UseHttpMutationOptions<Data, Response, Error> = {
  url?: string;
  data?: Data;
  method?: Method | string;
  headers?: Record<string, string>;
  api?: AxiosInstance; // New: Accept an Axios instance
  isAuthorized?: boolean; // New: flag for authorization
  /** Disable toast shown on error */
  disableErrorToast?: boolean;
} & UseMutationOptions<Response, Error, Data>;
/**
 * @example
 *
 * const { mutate, isLoading, error } = useHttpMutation({
 *  url: "/api/user",
 *  method: "POST",
 *  onSuccess: () => queryClient.invalidateQueries("user"),
 *  onError: (error) => console.error(error),
 * });
 */
export function useHttpMutation<
  Data = unknown,
  Response = unknown,
  Error = ApiError,
>(
  options?: UseHttpMutationOptions<
    UseHttpMutationFunctionProps<Data>,
    Response,
    Error
  >,
) {
  // Extract the axios options from the mutation options
  const {
    url,
    api,
    data,
    headers,
    onError,
    isAuthorized,
    method = 'POST',
    disableErrorToast = false,
    ...mutationOptions
  } = options || {};

  // Define the mutation function with an object containing data and optional config
  // This optional config can be used to provide additional axios settings at the time of mutation
  const mutationFn = async ({
    data,
    config,
  }: UseHttpMutationFunctionProps<Data>): Promise<Response> => {
    const response: AxiosResponse<Response> = await (api || baseApi).request({
      url,
      data,
      method,
      headers: {
        ...headers,
        ...(isAuthorized ? {} : { 'X-Skip-Auth': 'true' }), // Conditionally add the custom header
      },
      ...config, // Spread the config to allow overriding default settings
    });

    return response.data;
  };

  return useMutation<Response, Error, UseHttpMutationFunctionProps<Data>>({
    mutationFn,
    ...mutationOptions,
    onError: (error, ...args) => {
      logError({ error });

      if (!disableErrorToast) {
        toast.error(getApiErrorMessage(error));
      }

      onError?.(error, ...args);
    },
  });
}
