import { Column, ColumnProps } from '@kamona/components';

import { cn } from '@/lib/utils';

export function Page({
  className,
  align = 'center',
  container = 'xs',
  justify = 'center',
  gap = 'units-unit48',
  ...props
}: ColumnProps & {
  container?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}) {
  return (
    <Column
      as="main"
      {...props}
      {...{ gap, align, justify }}
      className={cn(
        'my-8 px-4 md:my-12 lg:my-16',
        // Make sure these classes exist in globals.scss
        `container-${container}`,
        className,
      )}
    />
  );
}
