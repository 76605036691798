import Image from 'next/image';

import { cn } from '@/lib/utils';

export type PageCoverProps = React.ComponentPropsWithoutRef<'figure'> & {
  coverSvg?: React.ReactNode;
  imgProps?: Omit<React.ComponentPropsWithoutRef<'img'>, 'placeholder'>;
};

export function PageCover({
  imgProps,
  className,
  coverSvg,
  ...props
}: PageCoverProps) {
  const { src = '', alt = '' } = imgProps || {};

  const imageProps = {
    ...imgProps,
    alt,
    src,
    height: 304,
    layout: 'responsive',
    objectFit: 'cover',
    width: 1108,
    className: 'w-full h-full object-cover',
  } satisfies Parameters<typeof Image>[0];

  if (coverSvg) {
    return coverSvg as JSX.Element;
  }

  return (
    <figure
      {...props}
      className={cn(
        'w-full h-auto aspect-[3.6] rounded-units-unit20 overflow-hidden',
        className,
      )}
    >
      {src ? (
        <Image
          {...imageProps}
          onError={(e) => {
            e.currentTarget.src = '/assets/images/checker.png';
          }}
          alt="Success image"
        />
      ) : (
        <Image
          {...imageProps}
          alt="Cover image"
          src={imageProps.src || '/assets/images/checker.png'}
        />
      )}
    </figure>
  );
}
