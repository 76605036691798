import { cn } from '@/lib/utils';

import { Text } from '../text';
import { PageSubtitle } from './page-subtitle';

export type PageTitleProps = React.ComponentPropsWithoutRef<'div'> & {
  title: string;
  subtitle?: React.ReactNode;
};

export function PageTitle({ title, subtitle, className }: PageTitleProps) {
  return (
    <div className={cn('space-y-units-unit20 text-center', className)}>
      <Text
        align="center"
        weight="heading"
        aria-label="page-title"
        variant="header-default"
        color="content-heading-default"
      >
        {title}
      </Text>
      {subtitle &&
        (typeof subtitle == 'string' ? (
          <PageSubtitle aria-label="page-subtitle">{subtitle}</PageSubtitle>
        ) : (
          subtitle
        ))}
    </div>
  );
}
