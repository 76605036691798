import { GenerateOtp } from '../../features/onboarding/signup/types';
import { useHttpMutation } from '@/shared/hooks';

const useGenerateOtp = (
  options?: Parameters<typeof useHttpMutation<GenerateOtp, void>>[0],
) => {
  const { onSuccess, onError, ...mutationClientOptions } = options || {};

  return useHttpMutation<GenerateOtp, void>({
    method: 'POST',
    url: '/identity/v1/verifications',
    ...mutationClientOptions,
  });
};

export default useGenerateOtp;
