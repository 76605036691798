'use client';

import { useHttpMutation } from '@/shared/hooks';
import { VerifyOtpPayload, VerifyOtpResponse } from '../types';

export const useVerifyOtpMutation = useHttpMutation<
  VerifyOtpPayload,
  VerifyOtpResponse
>;

const useVerifyOtp = (options?: Parameters<typeof useVerifyOtpMutation>[0]) => {
  const { onSuccess, onError, ...mutationClientOptions } = options || {};

  return useVerifyOtpMutation({
    method: 'POST',
    url: '/identity/v1/verification-checks',
    ...mutationClientOptions,
  });
};

export default useVerifyOtp;
